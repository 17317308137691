* {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  font-size: 100%;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

.unstyled-link {
  color: 'inherit';
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  main {
    height: calc(100vh - 110px);
    margin-top: 110px;
    background-color: black;
    background-image: url("../../public/images/invalidDeviceError.png");
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position-y: 37%;
  }
}
