#projects-main {
  background-color: rgba(12, 46, 61, 0.096);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 133px);
  margin-top: 133px;
  overflow: hidden;
}

#projects-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#projects-quote-container {
  margin-top: 18px;
  margin-bottom: 9px;
  display: inline-block;
}

#projects-quote {
  margin-top: 45px;
  padding: 2px;
  font-family: 'Courier New', monospace;
  letter-spacing: 3px;
  border-right: 2px transparent solid;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  animation: typing-project 4.5s steps(49), cursor-project 0.4s step-end 50 alternate;
}

#project-filters {
  background-color: #34f072a1;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 15px;
  height: 310px;
  margin-top: 150px;
  border-radius: 0px 45px 45px 0px;
  border-right: solid 2px rgb(5, 187, 45);
  transition: width 1s, background-color 1.8s;
}

#project-filters > * {
  visibility: hidden;
}

#project-filters:hover > * {
  visibility: visible;
}

#project-filters:hover {
  background-color: #ff7f504b;
  width: 245px;
  padding: 12px 7px 0px 5px;
  border-radius: 0px 9px 9px 0px;
  margin-top: 90px;
  border: solid 1px #ff7f504b;
  height: auto;
  max-height: calc(72vh - 134px);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
}

#project-filters::-webkit-scrollbar {
  width: 3px;
}

#project-filters::-webkit-scrollbar-thumb {
  background-color: #f0560f;
  border-radius: 0px 370px 370px 0px;
}

#type-select {
  border: none;
  padding: 5px 15px 5px 15px;
  width: 100%;
  color: black;
  background-color: #e7a274b0;
  border-bottom: solid 1px orange;
  letter-spacing: 1px;
  cursor: pointer;
}

#tech-filter {
  margin: 27px 0px 27px 15px;
  padding: 0px 0px 0px 0px;
}

#tech-filter label {
  display: block;
  margin-bottom: 12px;
  letter-spacing: 1px;
  cursor: pointer;
}

#tech-filter input[type="checkbox"] {
  margin-right: 7px;
}

#projects-carousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  max-height: calc(100vh - 270px);
  overflow-y: auto;
  padding: 27px;
}

#projects-carousel::-webkit-scrollbar {
  width: 5px;
}

#projects-carousel::-webkit-scrollbar-thumb {
  background-color: #264e81;
  border-radius: 7px;
}

.project-card {
  width: 270px;
  height: 250px;
  margin: 21px;
  box-shadow: 5px 5px 20px black;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  opacity: 0;
  animation: showCards linear 3.5s forwards;
}

.project-card img {
  width: 100%;
  height: 100%;
}

.project-info {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 18px;
  background-color: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  color: #fff;
  opacity: 0;
  transition: opacity 0.9s ease;
  overflow: auto;
}

.project-info::-webkit-scrollbar {
  width: 4px;
}

.project-info::-webkit-scrollbar-thumb {
  background-color: #939795;
  border-radius: 7px;
}

.project-card:hover .project-info {
  opacity: 1;
}

.project-info h2 {
  margin: 5px;
  margin-left: 15px;
  font-size: 21px;
  color: #ff7f50;
  letter-spacing: 4px;
  transition: color 0.3s, font-size 0.3s, transform 0.3s;
}

.project-info h2:hover {
  color: #ff4400;
  font-size: 23px;
  transform: translateY(-2px);
}

#project-page-link {
  color: #ff7f50;
}

#project-page-link:visited {
  color: #ff7f50;
}

.project-info p {
  margin: 10px 0;
  margin-bottom: 21px;
  font-size: 16px;
}

.github-link-btn {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 7px;
  font-weight: bold;
  transition: background-color 0.7s ease;
}

.github-link-btn:hover {
  background-color: #007bff;;
}

#github-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 94%;
  left: 96%;
  width: 90px;

  opacity: 0;
  animation: fadeIn 5s, opacity 5s forwards;
}

#github-contact img {
  width: 37px;
}

@keyframes typing-project {
  from { width: 0 }
}

@keyframes cursor-project {
  50% { border-color: black }
}

@keyframes showCards {
  to {  opacity: 1 }
}

@media screen and (max-width: 900px) {
  #projects-main {
    background-color: black;
  }

  #projects-quote {
    display: none;
  }

  #project-filters {
    display: none;
  }

  .project-card {
    display: none;
  }
}
