header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: rgb(0, 0, 0);
  border-bottom: solid 3px yellow;
}

#head-logo {
  width: 270px;
}

#head-title {
  width: 330px;
  margin-left: 45px;
}

header nav {
  position: relative;
  margin-right: 45px;
}

header a {
  text-decoration: none;
  font-weight: bold;
  padding: 0 11px;
  cursor: pointer;
  color: yellow;
}

#nav-home-btn:hover {
  color: red;
}

#nav-2061-btn:hover {
  color: orange;
}

#nav-projects-btn:hover {
  color: green;
}

#nav-wophi-btn:hover {
  color: blue;
}

@media screen and (max-width: 900px) {
  #head-logo {
    display: none;
  }

  header {
    flex-direction: column;
    padding: 7px 0;
    cursor: not-allowed;
  }

  #head-title {
    width: 270px;
    padding-top: 1px;
  }

  header nav {
    right: 0;
    padding: 5px 0 3px 0;
    pointer-events: none;
  }

  header a {
    padding: 13px;
  }

  #nav-home-btn {
    color: rgba(255, 0, 0, 0.500);
  }

  #nav-2061-btn {
    color: rgba(255, 166, 0, 0.500);
  }

  #nav-projects-btn {
    color: rgba(0, 128, 0, 0.500);
  }

  #nav-wophi-btn {
    color: rgba(0, 0, 255, 0.500);
  }
}
