#about-project-main {
  background-color: rgba(12, 46, 61, 0.096);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 133px);
  margin-top: 133px;
  padding: 20px;
  overflow: hidden;
}

#project-title {
  margin-top: 27px;
  font-family: 'Cinzel', serif;
  font-size: 3em;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

#project-content {
  margin-top: 45px;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#about-project-section {
  flex: 1 1 auto;
  padding: 45px;
  background-color: rgba(236, 234, 234, 0.9);
  border-radius: 25px;
  word-break: break-word;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s, opacity 0.7s ease;
  opacity: 0.9;
}

#about-project-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  opacity: 1;
}

#about-project-section p {
  font-family: 'Georgia', serif;
  font-size: 1.1em;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #333;
  padding: 5px 10px;
}

aside {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

aside h3 {
  margin-bottom: 10px;
  font-family: 'Cinzel', serif;
  color: #444;
  font-size: 1.5em;
}

#about-project-section ul {
  list-style: none;
  padding: 0;
}

#about-project-section li {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f0f0f0;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

#about-project-section li:hover {
  background-color: #e0e0e0;
  transform: translateY(-3px);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #000408;
  border-radius: 7px;
}

#github-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 27px;
}

#github-button {
  display: inline-flex;
  align-items: center;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}

#github-button:hover {
  background-color: #45a049;
}

#github-button i {
  margin-right: 9px;
}

@media screen and (max-width: 900px) {
  #about-project-main {
    background-color: black;
    height: calc(100vh - 133px);
  }

  #project-title {
    display: none;
  }

  #project-content {
    display: none;
  }
}
