#wophi-main {
  background-color: rgba(12, 46, 61, 0.096);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 133px);
  margin-top: 133px;
  overflow: hidden;
}

#wophi-quote {
  margin-top: 70px;
  border-radius: 27px;
  padding: 27px;
  opacity: 0;
  animation: showWophiQuote 7s 9s linear forwards;
  text-align: center;
}

#wophi-quote p {
  padding-top: 5px;
  color: rgba(240, 209, 255, 0.795);
  cursor: pointer;
}

.red-color {
  color: red !important;
  transition-duration: 4s;
}

.orange-color {
  color: orange !important;
  transition-duration: 4s;
}

.green-color {
  color: green !important;
  transition-duration: 4s;
}

.blue-color {
  color: blue !important;
  transition-duration: 4s;
}

.purple-color {
  color: purple !important;
  transition-duration: 4s;
}

#Einsten {
  width: 100%;
  padding-top: 45px;
  opacity: 1;
}

#Einsten:hover {
  opacity: 1;
}

#start-wophi-button {
  margin-top: 9px;
  padding: 2px 5px;
  cursor: pointer;
  border: 2px solid black;
  box-shadow: 2px 2px 0 0 white, 4px 4px 0 0 black;
  transition-duration: 200ms;
}

#start-wophi-button:hover {
  box-shadow: 3px 3px 0 0 white, 6px 6px 0 0 black;
  transform: translate(-2px, -2px);
  cursor: not-allowed;
}

@keyframes showWophiQuote {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  #wophi-main {
    background-color: black;
  }

  #wophi-quote {
    display: none;
  }
}
