#halley-main {
  background-color: rgba(12, 46, 61, 0.096);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 133px);
  margin-top: 133px;
  overflow: hidden;
}

#halley-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#halley-quote-container {
  margin-top: 70px;
}

#halley-quote {
  text-align: center;
  padding: 70px;
  font-family: 'Courier New', monospace;
}

#floating-austronaut {
  width: 180px;
  transition: top 0.5s, left 0.5s;
}

@media screen and (max-width: 900px) {
  #halley-main {
    background-color: black;
  }

  #halley-quote-container {
    display: none;
  }

  #floating-austronaut {
    display: none;
  }
}
