#home-main {
  background-color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 133px);
  margin-top: 133px;
  overflow: hidden;
}

#home-video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

#section-home-quote {
  opacity: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../public/images/mColors.png");
  animation: fadeIn 6s 2s forwards, zoomOut 42s ease-in-out forwards, zoomIn 9s 70s ease-in-out forwards, showCursor 0s 80s forwards;
  width: 100%;
  height: 100%;
  cursor: none;
}

#home-quote {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 180px;
  padding: 9px;
  font-size: 1.1rem;
  letter-spacing: 3px;
  color: black;
}

#home-quote p {
  font-family: 'Courier New', monospace;
  white-space: nowrap;
  overflow: hidden;
  padding: 7px;
}

#home-quote-p1, #home-quote-p2, #home-quote-p3, #home-quote-p4, #home-quote-p5 {
  visibility: hidden;
}

#home-quote-p1 {
  width: 49ch;
  animation: typing 4s 3.5s steps(37) forwards, blinking 0.4s step-end 20 alternate;
}

#home-quote-p2 {
  width: 67ch;
  animation: typing 5.5s steps(51) forwards, blinking 0.4s step-end 15 alternate;
  animation-delay: 8s;
}

#home-quote-p3 {
  width: 84ch;
  animation: typing 7s steps(65) forwards, blinking 0.4s step-end 18 alternate;
  animation-delay: 14.5s;
}

#home-quote-p4 {
  width: 84ch;
  animation: typing 7s steps(64) forwards, blinking 0.4s step-end 18 alternate;
  animation-delay: 22s;
}

#home-quote-p5 {
  width: 71ch;
  animation: typing 5.8s steps(54) forwards, blinking 0.4s step-end 21 alternate;
  animation-delay: 29s;
}

#home-quote-p6 {
  width: 51ch;
  text-transform: uppercase;
  font-weight: bold;
  color: transparent;
  animation: rainbowText 0s forwards, typing 5s steps(38) forwards, whiteBlinking 0.7s step-end infinite alternate, glow 9s linear infinite;
  animation-delay: 45.5s;
}

#golden-key, #golden-lock {
  opacity: 0;
  position: absolute;
  filter: drop-shadow(0 0 0 yellow);
  animation: goldenBlink 3s infinite alternate, fadeIn 5s, opacity 5s forwards;
  animation-delay: 25s;
}

#contact-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 94%;
  left: 96%;
  width: 90px;

  opacity: 0;
  animation: fadeIn 5s, opacity 5s forwards;
  animation-delay: 25s;
}

#contact-info img {
  width: 37px;
}

#golden-lock {
  width: 30px;
}

#golden-key {
  width: 45px;
}

#golden-key:hover {
  cursor: not-allowed;
}

.about-media {
  position: absolute;
  animation: colorBlink 3s infinite alternate, fadeIn 3s;
}

.about-media-item-placeholder {
  background-color: rgba(248, 244, 244, 0.226);
  width: 27px;
  height: 27px;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  transition: background-color 0.5s ease-in-out;
  cursor: none;
}

.about-media-item {
  opacity: 0.2;
  width: 27px;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  transition: width 2s ease-in-out, transform 0.5s ease-in-out, opacity 2s ease-in-out;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  cursor: none;
}

.about-media-item:hover {
  position: absolute;
  z-index: 1;
  opacity: 1;
  clip-path: none;
  width: 270px;
  border-radius: 9%;
  transform: translate(-50%, -50%) scale(1.2);
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    background-size: 9000%;
    background-position: center;
  }
  to {
    background-size: 0%;
    background-position: center;
  }
}

@keyframes zoomIn {
  from {
    background-size: 0%;
    background-position: center;
  }
  to {
    background-size: 5000%;
    background-position: center;
    padding-right: 370px;
  }
}

@keyframes showCursor {
  to {
    cursor: auto;
  }
}

@keyframes typing {
  from {
    width: 0;
    border-right: 3px solid transparent;
    visibility: visible;
  }
  to {
    border-right: 3px solid transparent;
    visibility: visible;
  }
}

@keyframes blinking {
  50% {
    border-color: black;
  }
}

@keyframes whiteBlinking {
  50% {
    border-color: white;
  }
}

@keyframes rainbowText {
  from, to {
    background: linear-gradient(45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000);
    background-size: 600% 100%;
    background-clip: text;
    -webkit-background-clip: text;
  }
}

@keyframes glow {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 100%;
  }
}

@keyframes goldenBlink {
  0% {
    filter: drop-shadow(0 0 0 yellow);
  }
  100% {
    filter: drop-shadow(0 0 20px yellow);
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes colorBlink {
  0% {
    opacity: 1;
    filter: drop-shadow(0 0 2px #ff0000);
  }
  5% {
    filter: drop-shadow(0 0 0 #ff7300);
  }
  20% {
    opacity: 1;
    filter: drop-shadow(0 0 7px #fffb00);
  }
  35% {
    filter: drop-shadow(0 0 0 #48ff00);
  }
  50% {
    opacity: 1;
    filter: drop-shadow(0 0 7px #00ffd5);
  }
  65% {
    filter: drop-shadow(0 0 3px #002bff);
  }
  70% {
    filter: drop-shadow(0 0 0 #7a00ff);
  }
  85% {
    filter: drop-shadow(0 0 0 #ff00c8);
  }
  100% {
    filter: drop-shadow(0 0 3px #ff0000);
  }
}

@media screen and (max-width: 1272px) {
  #contact-info {
    left: 90%;
  }

  @keyframes zoomIn {
    from {
      background-size: 0%;
      background-position: center;
    }
    to {
      background-size: 5000%;
      background-position: center;
    }
  }
}

@media screen and (max-width: 900px) {
  #home-video-container video {
    display: none;
  }
}
