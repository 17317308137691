#matrix-chart {
  width: 54%;
  position: absolute;
  margin-top: 33px;
  height: 137px;
}

#mirror-matrix-chart {
  width: 54%;
  position: absolute;
  margin-top: 33px;
  height: 137px;
  left: 58%;
}

@media screen and (max-width: 900px) {
  #matrix-chart, #mirror-matrix-chart {
    display: none;
  }
}
